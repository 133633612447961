import { createStore } from 'vuex'

//具体状态数据
const state={
   inNavshow:true,  //底部导航显示与隐藏
}

//计算属性
const getters={
    shownav(state){
      return state.inNavshow
    }
}

//管理mutations,
const actions={
  showNav({commit}){
    commit('showNav')
  },
  hideNav({commit}){
    commit('hideNav')
  }
}

//修改数据
const mutations={
  showNav(state){
     state.inNavshow=true;
  },
  hideNav(state){
    state.inNavshow=false;
  }
}

//导出store对象
const store=createStore({
  state,
  getters,
  actions,
  mutations
})

export default store;
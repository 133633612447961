<template>
    <div class="login">
        <div class="login-form">
            <h1 class="title">登录</h1>
            <van-form @submit="onSubmit">
                <van-cell-group inset>
                    <van-field v-model="mobile" placeholder="请输入手机号" name='mobile' :rules="[{ pattern:userMessage, message: '请输入正确手机号' }]"/>
                    <van-field v-model="code" center clearable placeholder="请输入短信验证码" name='code' :rules="[{ pattern:codeMessage, message: '请输入正确的验证码' }]">
                        <template #button>
                            <van-button v-if='codeStatus' size="small" type="primary" @click="submitCode">{{codeText}}</van-button>
                            <van-button v-else size="small" type="primary">{{codeTexts}}{{codeNuber}}s</van-button>
                        </template>
                    </van-field>
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit">登录</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
import {ref,reactive,onMounted} from 'vue';
import {useRouter} from 'vue-router'
import {getcode,getlogin} from '../../request/api'
import Uitl from '../../uitl/uitl'
import { Toast} from 'vant';
import 'vant/es/toast/style'

export default {
    setup(){
        let router=useRouter();
        const userMessage=/^1[345789]\d{9}$/
        const codeMessage=/^[0-9]\d*$/
        let codeText=ref('发送验证码');
        const codeTexts=ref('己发送')
        const codeNuber=ref(60)
        const codeStatus=ref(true)
        const mobile=ref('');
        const code=ref('');

        
        //获取验证码
        let submitCode=()=>{
            let timer;
            if(mobile.value=='')return Toast.fail('手机号码不能为空！');
            codeStatus.value=false;
            timer=setInterval(()=>{
                codeNuber.value--;
                if(codeNuber.value==0){
                    codeStatus.value=true;
                    codeNuber.value=60
                    clearInterval(timer)
                }
            },1000)
            console.log(getcode(),'验证码')
            getcode({mobile:mobile.value}).then(res=>{
                if(res.code!==200){
                    Toast({
                        message: '验证码获取失败！',
                        position: 'top',
                    });
                }
            })
            
        }
        // 登录
        let onSubmit=(values)=>{
            values.registration_id=JpusDId
            getlogin(values).then(res=>{
                if(res.code==200){
                    Uitl.saveToken('Authorization',res.data.accessToken)
                    let accessToken=res.data.accessToken
                    // if(accessToken==null || accessToken=='')accessToken==''
                    // JsInterface.login(res.data.accessToken,'seller_director',values.mobile)
                    router.push('/home')
                }else{
                    Toast({
                        message:res.msg,
                        position: 'top',
                    });
                }
            })
        }
        onMounted(()=>{
           
        })
        return{
            userMessage,codeMessage,codeStatus,codeText,codeNuber,codeTexts,mobile,code,onSubmit,submitCode
       }
    }
}
</script>

<style scoped>
    .login{
        display:flex;
        align-items:center;
        width:100%;
        height:100vh;
        background:url('../../assets/images/login.png') no-repeat 0 0/cover;
    }
    .login-form{
        padding:30px 15px 50px 15px;
        margin:auto;
        width:80%;
        background-color:#fff;
        border-radius:8px;
    }
    .title{
        padding-bottom:15px;
    }
</style>
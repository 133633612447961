
//引入axios
import axios from 'axios'
import Uitl from '../uitl/uitl'
//获取本地存储

//配置地址
axios.defaults.baseURL=''

//添加请求拦截器---》发送请求之前做些什么
axios.interceptors.request.use((config)=>{
  //在头部添加token
  //为了测试，写死的token
  // config.headers ={"token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1"}
  //为了获取本地缓存数据
  if(Uitl.getToken('Authorization')==undefined || Uitl.getToken('Authorization')=={}){
    config.headers ={"Authorization":'addf'}
  }else{
    config.headers ={"Authorization":Uitl.getToken('Authorization')}
  }
  return config
})

//添加响应拦截器
axios.interceptors.response.use((response)=>{
      //对响应体数据做些什么
      return response
},err=>{
      //对响应体错误做些什么
      return Promise.reject(err)
})


//post请求方式
export function reqPost(url, params){
      return new Promise((resolve, reject) => {
          console.log("请求的URL" + url)
          axios.post(url,params)
          .then(res=>{
            console.log("resolve", res)
            // 登录返回302，需要处理头部的tokenid
            if(url == "/fatapp/api/v1/base/login" && res.data.code == 302){
              let preToken = res.headers.tokenid;
              Uitl.savePreToken(preToken);
            }
            resolve(res.data)
          })
          .catch(err=>{
            console.log("reject", err)
            reject(err.data)
          })
      })
  }
  //get请求方式let headers ={"token":"b1bbe643-07ae-416d-af58-b9ae7733d4c3"}
  export function reqGet(url, params) {
      return new Promise((resolve, reject) => {
          axios.get(url,{
            params:params,
          }).then(res => {
              resolve(res.data);
           })
         .catch(err => {
              reject(err.data)
          })
      });
  }
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/fonts.css'

import {Button,Form, Field, CellGroup,Tabbar, TabbarItem,Swipe, SwipeItem,Icon,Popup,DatetimePicker,
    Picker,PullRefresh,List,NavBar, Cell,Tab, Tabs ,RadioGroup, Radio,Dialog,Overlay,ActionSheet} from 'vant'

import 'vant/es/button/style';
import 'vant/es/form/style'
import 'vant/es/field/style'
import 'vant/es/tabbar/style'
import 'vant/es/tabbar-item/style'
import 'vant/es/swipe/style'
import 'vant/es/swipe-item/style'
import 'vant/es/icon/style'
import 'vant/es/popup/style'
import 'vant/es/datetime-picker/style'
import 'vant/es/picker/style'
import 'vant/es/pull-refresh/style'
import 'vant/es/list/style'
import 'vant/es/nav-bar/style'
import 'vant/es/cell/style'
import 'vant/es/cell-group/style'
import 'vant/es/tab/style'
import 'vant/es/radio/style'
import 'vant/es/radio-group/style'
import 'vant/es/dialog/style'
import 'vant/es/overlay/style'
import 'vant/es/action-sheet/style'


createApp(App).use(store).use(router)
.use(Button)
.use(Form)
.use(Field)
.use(CellGroup)
.use(Tabbar)
.use(TabbarItem)
.use(Swipe)
.use(SwipeItem)
.use(Icon)
.use(Popup)
.use(DatetimePicker)
.use(Picker)
.use(PullRefresh)
.use(List)
.use(NavBar)
.use(Cell)
.use(CellGroup)
.use(Tab)
.use(Tabs)
.use(Radio)
.use(RadioGroup)
.use(Dialog)
.use(Overlay)
.use(ActionSheet)


.mount('#app')

let JpusDId=''
function getJPushDeviceId(e){
    JpusDId=e;
}

window.getJPushDeviceId=getJPushDeviceId
window.JpusDId=JpusDId
//调用请求方式
import {reqPost,reqGet} from './http.js';

//----------获取code--------
export const getcode = p=> reqPost('/app/v1/auth/send_code',p)

//----------登录----------
export const getlogin = p=> reqPost('/app/v1/auth/code_login',p)

//----------首页计划列表----------
export const getplan = p=> reqPost('/app/v1/index',p)

//-----------数据分析--------------
export const getanalysis = p=> reqPost('/app/v1/data_analysis',p)

//-------------客户管理------------
export const getcustomer = p=> reqPost('/app/v1/company/list',p)
//客户管理详情
export const getcustomerdeatil = p=> reqPost('/app/v1/company/detail',p)
//资金出入账管理
export const getmoney=p=>reqPost('/app/v1/financial/ocr',p)

//-------------当前计划-------------
export const getcurrlist = p=> reqPost('/app/v1/order/curr_list',p)
//审核与驳回
export const getexm = p=>reqPost('/app/v1/order/seller/exm',p)
//当前计划详情
export const getcurrlistdeatil = p=> reqPost('/app/v1/order/curr_detail',p)
//历史计划
export const gethositylist = p=> reqPost('/app/v1/order/history_list',p)
//历史详情
export const gethositydeatil=p=>reqPost('/app/v1/order/history_detail',p)
//自建计划
export const getcreate=p=>reqPost('/app/v1/order/create',p)
//定时调价
export const geteditprice=p=>reqPost('/app/v1/goods/time/edit_price',p)
//调价
export const getexe=p=>reqPost('/app/v1/order/exe',p)

//----------财务----------
//获取客户列表
export const getoption=p=>reqPost('/app/v1/company/option',p)
//获取产品
export const getdropdown=p=>reqGet('/app/v1/goods/dropdown',p)
//新增采购计划
export const getpurchase=p=>reqPost('/app/v1/order/purchase_create',p)
//财务列表
export const getfinacial=p=>reqPost('/app/v1/financial/curr_list',p)
//历史计划列表
export const getfonacialhosity=p=>reqPost('/app/v1/financial/history',p)

//------------安检----------
//待安检列表
export const getwait=p=>reqGet('/app/v1/order/wait_check',p)
//待安检提交列表
export const getcheck=p=>reqPost('/app/v1/order/check',p)
//------------充装-----------
//充装列表
export const getgauger=p=>reqGet('/app/v1/order/wait_gauger',p)
//充装提交
export const getgaugercheck=p=>reqPost('/app/v1/order/gauger_check',p)
//充装压高
export const getmark=p=>reqPost('/app/v1/order/gauger_mark',p)

//----------个人中心----------
export const getinfo=p=>reqPost('/app/v1/auth/info',p)

//----------消息--------------
export const getmessage=p=>reqPost('/app/v1/message/list',p)
export const getmessagedeatil=p=>reqPost(`/app/v1/message/detail/${p}`)

//-----------退出登录----------
export const logout=p=>reqPost('/app/v1/auth/logout',p)



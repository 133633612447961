import { createRouter, createWebHashHistory } from 'vue-router'

import Login from '../views/login/login.vue'

const routes = [
  {
    path:'/',
    redirect:'Login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path:'/home',
    name:'home',
    component:()=>import('../views/home/home.vue'),
    children:[
      {
        path:"/index",
        name:"index",
        component:()=>import('../views/index/index.vue')
      },
      {
        path:"/audit",
        name:"audit",
        component:()=>import('../views/audit/audit.vue')
      },
      {
        path:"/aduithosity",
        name:'aduithosity',
        component:()=>import('../components/audit/history.vue')
      },
      {
        path:'/hositydeatil',
        name:'hositydeatil',
        component:()=>import('../components/audit/hositydetail.vue')
      },
      {
        path:'/currentdeatil',
        name:'currentdeatil',
        component:()=>import('../components/audit/currentdetail.vue')
      },
      {
        path:'/add',
        name:'add',
        component:()=>import('../components/audit/addform.vue')
      },
      {
        path:"/analysis",
        name:"analysis",
        component:()=>import('../views/analysis/analysis.vue')
      },
      {
        path:"/customer",
        name:"customer",
        component:()=>import('../views/customer/customer.vue')
      },
      {
        path:'/customerDeatil',
        name:'customerDeatil',
        component:()=>import('../components/customerDeatil/customerDeatil.vue')
      },
      {
        path:"/personal",
        name:"personal",
        component:()=>import('../views/personal/personal.vue')
      },
      {
        path:'/financial',
        name:'financial',
        component:()=>import('../views/financial/financial.vue')
      },
      {
        path:'/listdeatil',
        name:'listdeatil',
        component:()=>import('../components/financial/deatil.vue')
      },
      {
         path:'/hositylist',
         name:'hositylist',
         component:()=>import('../components/financial/hositylist.vue')
      },
      {
        path:'/finandeatil',
        name:'finandeatil',
        component:()=>import('../components/financial/finandeatil.vue')
      },
      {
        path:'/addfinancial',
        name:'addfinancial',
        component:()=>import('../components/financial/add.vue')
      },
      {
        path:'/security',
        name:'security',
        component:()=>import('../views/security/security.vue')
      },
      {
        path:'/filling',
        name:'filling',
        component:()=>import('../views/filling/filling.vue')
      },
      {
        path:'/securitydeatil',
        name:'securitydeatil',
        component:()=>import('../components/security/deatil.vue')
      },
      {
        path:'/fillingdeatil',
        name:'fillingdeatil',
        component:()=>import('../components/filling/fillingdeatil.vue')
      },
      {
        path:'/moneydeatil',
        name:'moneydeatil',
        component:()=>import('../views/moneydeatil/moneydeatil.vue')
      },
      {
        path:'/message',
        name:'message',
        component:()=>import('../views/message/message.vue')
      },
      {
        path:'/messagedeatil',
        name:'messagedeatil',
        component:()=>import('../components/message/messagedeatil.vue')
      }
    ]
  },
  
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router


<template>
  <router-view/>
</template>

<script>
import{onMounted} from 'vue'
import {useStore} from 'vuex'
export default{
  setup() {
    let store=useStore();

    console.log(store,112332)
  },
}
</script>


<style>
*{
  margin:0;
  padding:0;
}
#app {
  font-family:fonts;
}

</style>

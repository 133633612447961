/*
封装本地缓存和获取本地缓存以及删除本地缓存
*/
export default{
    //保存数据
    saveToken:function(name,value){  //t 代表token得简写
      localStorage.setItem(name,JSON.stringify(value))
    },
    //获取数据
    getToken:function(name){
        return JSON.parse(localStorage.getItem(name)||'{}')
    },
    //删除数据
    removeToken:function(){
        localStorage.removeItem('token')
    },
    getQueryVariable:function (variable){
    //  var url =  'http://www.runoob.com/index.php?uid=1&token=awesome.jpg';
    // var query =url.slice(url.indexOf('?')).substring(1);;
    var query = window.location.href.slice(window.location.href.indexOf('?')).substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }
}
